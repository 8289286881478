import styled from 'styled-components';
import { device } from '../../utils/breakpoints.js';


export const Container = styled.div`
	
	.advertisement {
		margin: 40px auto;

		@media ${device.desktop} {
			margin: 55px auto;
		}
	}
`;

export const TopPagination = styled.div`
	display: flex;
	justify-content: center;
	max-width: ${(props) => props.theme.mainMaxWidth};
	margin: 0 auto;
	width: ${(props) => `calc(100% - 2 *${props.theme.mobileGutter})`};
	padding-top: 10px;

	@media ${device.tablet} {
		padding-top: 25px;
	}

	@media ${device.desktop} {
		padding-top: 44px;
		/* justify-content: flex-end; */
		width: 100%;
	}

	/* .container {
		height: 30px;
	}

	.button {
		width: 55px;
	} */
`;
