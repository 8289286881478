import React from 'react';
import { CategoryLandingTemplate } from '../components/CategoryLandingTemplate';
import Layout from '../components/layout';
import SEO from '../components/seo';

const CategoryLanding = (props) => {
	const { pageContext } = props;
	const { name, postsAndEditorials, categoryData, description, slug, count } = pageContext;

	return (
		<Layout category title={name} headerImage={categoryData.categoryHeroImage} description={description}>
			<SEO category title={`${name} inspiration`} description={description} image={categoryData.categoryHeroImage} url={`${process.env.GATSBY_WEBSITE_DOMAIN}/category/${slug}/`} />
			<CategoryLandingTemplate count={count} initialPosts={postsAndEditorials.nodes} name={name} related={categoryData.relatedCategories}/>
		</Layout>
	);
};

export default CategoryLanding;
