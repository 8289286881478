import React from "react"
import { Container } from "./relatedCategoriesStyles"

import { Categories } from "../Categories/index"

const relatedCategories = ({related}) => {
  return (
    <Container>
      <Categories related={related} title="Related categories:" category />
    </Container>
  )
}

export default relatedCategories
