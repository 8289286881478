import React, { useState, useEffect, useCallback, useRef } from "react"

import { Container } from "./style.js"
import { TopIndicator, BottomPagination, Number } from "../SearchPage/style.js"

import { RelatedCategories } from "../RelatedCategories/index"
import { ResultsGrid } from "../TeaserGrids/index.js"
import algoliasearch from "algoliasearch"
import { Pagination } from "../Pagination/index.js"

import { AdvertisementWrapper } from "../AdvertisementWrapper"
import { GoogleAd } from "../GoogleAd"

const client = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_API_KEY
)
const index = client.initIndex(process.env.GATSBY_ALGOLIA_INDEX_NAME)

const CategoryLandingTemplate = ({ initialPosts, name, related, count }) => {
  const isFirstRun = useRef(true)
  const postsPerPage = 30
  const [posts, setPosts] = useState([])
  const [totalPosts] = useState(count)
  const [currentPage, setCurrentPage] = useState(1)
  const lastPage = Math.ceil(totalPosts / postsPerPage)

  /**** ALGOLIA DEV ****/

  const findResults = useCallback(() => {
    index.search(
      {
        page: currentPage - 1,
        facetFilters: [[`categories: ${name}`]],
      },
      (err, data = {}) => {
        if (err) throw err
        setPosts(data.hits)
        console.log("search ran")
      }
    )
  }, [currentPage, name])

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }

    findResults()
  }, [findResults])

  return (
    <Container>
      <RelatedCategories related={related} />
      <AdvertisementWrapper horizontal id="topAd">
        <GoogleAd
          className="horizontal"
          client="ca-pub-4461681428787239"
          slots={["5279974588", "6732200204", "1479873529"]}
        />
      </AdvertisementWrapper>

      <TopIndicator hide={currentPage === 1}>
        <Number className="number">
          <p>
            <span>{totalPosts}</span> result{totalPosts === 1 ? null : "s"}{" "}
            found. Showing page <span> {currentPage}</span> of{" "}
            <span>{lastPage}</span>.
          </p>
        </Number>
      </TopIndicator>

      <ResultsGrid posts={currentPage === 1 ? initialPosts : posts} />

      <BottomPagination category>
        <Number className="number">
          <p>
            <span>{totalPosts}</span> result{totalPosts === 1 ? null : "s"}{" "}
            found. Showing page <span> {currentPage}</span> of{" "}
            <span>{lastPage}</span>.
          </p>
        </Number>

        <Pagination
          scrollId="topAd"
          topOfElement
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          postsPerPage={postsPerPage}
          lastPage={lastPage}
          totalPosts={totalPosts}
          bottom
        />
      </BottomPagination>

      <AdvertisementWrapper horizontal>
        <GoogleAd
          className="horizontal"
          client="ca-pub-4461681428787239"
          slots={["8169861808", "2639884280", "4775093039"]}
        />
      </AdvertisementWrapper>
    </Container>
  )
}

export default CategoryLandingTemplate
